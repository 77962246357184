.event_title {
  font-size: 36px;
  width: 100%;
  text-align: center;
  margin: 24px 0px 12px 0px; }
  .event_title::first-letter {
    text-transform: capitalize; }

.ant-descriptions-title {
  margin-bottom: 0px; }

.event_body {
  margin: 12px 0px 24px 0px;
  text-align: justify; }

.event_card {
  margin: 10px auto; }

.event_sub_title {
  text-align: center;
  font-size: 24px;
  padding: 0;
  font-weight: 100; }
