.maincontent {
  max-width: 1200px;
  width: 80%;
  margin: auto; }

.section {
  width: 100%;
  min-height: 360px;
  max-height: 860px;
  margin: 0 auto;
  overflow: hidden; }

.firstBanner {
  background: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%); }

.hands_image {
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  min-height: 310px;
  top: 0%;
  display: block; }

.page_title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
  position: static;
  width: 100%; }

.page_quote {
  font-size: 18px; }

.contact_button {
  margin-bottom: 20px;
  display: block; }

@media screen and (max-width: 767px) {
  .page_title {
    margin-top: 15px; }
  .page_quote {
    margin-top: 15px; } }

@media screen and (min-width: 767px) {
  .hands_image {
    margin: 0 25%; }
  .page_title {
    margin-top: 30%; }
  .contact_button {
    left: 20%;
    width: 60%; } }

.values {
  margin: 30px auto;
  width: 100%; }
  .values_heading {
    font-size: 36px;
    text-align: center;
    margin-top: 24px; }
  .values_title {
    font-size: 20px;
    text-align: center;
    margin: 10px auto; }
  .values_image {
    align-content: center;
    max-width: 100px;
    height: 100px;
    display: block;
    position: relative;
    margin: 0px auto; }
  .values_content {
    text-align: center;
    max-width: 80%;
    margin: 0px auto; }

.home_stats {
  margin: 30px auto;
  min-height: 160px;
  align-content: center; }
  .home_stats_full {
    background: linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.02));
    padding: 20px 0px; }
  .home_stats_stats_block {
    margin: 0px auto;
    display: block; }
  .home_stats_content {
    font-size: 20px;
    text-align: justify; }
  .home_stats_statistic {
    text-align: center;
    margin: 0px auto;
    color: #5f9bf1; }

.ant-statistic-content {
  color: #5f9bf1; }

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); } }

.ticker_wrap {
  width: 100%;
  overflow: hidden;
  box-sizing: content-box; }
  .ticker_wrap .ticker {
    display: inline-block;
    white-space: nowrap;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 60s;
    animation-duration: 60s; }
    .ticker_wrap .ticker__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 2rem; }
    .ticker_wrap .ticker:hover {
      animation-play-state: paused; }

.sponsors img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }
