.maincontent {
  max-width: 1200px;
  width: 80%;
  margin: auto; }

.section {
  width: 100%;
  min-height: 360px;
  max-height: 860px;
  margin: 0 auto;
  overflow: hidden; }

.aboutUsBanner {
  background: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%); }

.heading {
  font-size: 88px;
  font-style: italic;
  font-family: Georgia, serif;
  color: #5f9bf1;
  text-align: center;
  margin: auto;
  padding-top: 10%;
  position: static;
  width: 100%; }

.aboutus_heading {
  position: relative;
  text-decoration: none;
  font-size: 38px;
  font-family: '';
  text-align: center;
  margin: auto;
  padding-top: 2%; }

.aboutus_title {
  font-size: 20px;
  text-align: center;
  margin: 10px auto;
  padding-top: 2%; }

.aboutus_content {
  font-family: inherit;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
  max-width: 80%;
  margin: 0px auto; }

.whatwedo_heading {
  border-bottom: 1px solid #7d7b7a;
  position: relative;
  text-decoration: none;
  font-size: 38px;
  font-family: '';
  text-align: center;
  margin: auto;
  padding-top: 2%; }

.whatwedo_title {
  font-size: 20px;
  text-align: center;
  margin: 10px auto;
  padding-top: 1%; }

.whatwedo_image {
  padding-top: 5%;
  align-content: center;
  max-width: 110px;
  height: 110px;
  display: block;
  position: relative;
  margin: 0px auto;
  transition: transform .2s; }

.whatwedo_image:hover {
  transform: scale(1.2); }

.whatwedo_content {
  font-family: inherit;
  padding-top: 10px;
  font-size: 15px;
  text-align: center;
  max-width: 80%;
  margin: 0px auto; }

.ourteam_heading {
  border-bottom: 1px solid #7d7b7a;
  position: relative;
  text-decoration: none;
  font-size: 38px;
  font-family: '';
  text-align: center;
  margin: auto;
  padding-top: 2%; }

.ourteam_title {
  font-size: 20px;
  text-align: center;
  margin: 2px auto;
  padding-top: 2%; }

.ourteam_icon {
  font-size: 30px;
  text-align: center;
  margin: -4px auto; }

.ourteam_icon:hover {
  color: #5f9bf1; }

.ourteam_zoom {
  transition: transform .2s;
  /* Animation */
  margin: 0 auto; }

.ourteam_zoom:hover {
  transform: scale(1.2);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */ }

.ourteam_image {
  padding-top: 15%;
  height: 300px;
  display: block;
  position: relative;
  margin: 0px auto; }
