.logo {
  background-image: url("../images/scio_200_63_px.jpg");
  width: 200px;
  height: 63px;
  float: left;
  position: relative; }

.ant-menu-horizontal {
  border-bottom: none; }

.menu_icon {
  font-size: 24px;
  float: right;
  position: relative; }

.inlinelist {
  display: inline; }

.topnavbar {
  z-index: 100; }
