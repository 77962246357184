.flogo {
  display: block;
  margin: auto;
  text-align: center; }

.ant-layout-footer {
  background: white; }

.copyright {
  text-align: center; }
